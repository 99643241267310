
.navigation-toggle[data-v-741978ef] {
	float: left;
}
.site-logo[data-v-741978ef] {
	float: left;
	height: 56px;
	margin-left: 10px;
}
.site-title[data-v-741978ef] {
	margin-top: 5px;
	font-size: 30px;
	margin-left: 15px;
	line-height: 50px;
	letter-spacing: -1px;
	white-space: nowrap;
	float: left;
}
@media (max-width: 960px) {
.site-title[data-v-741978ef] {
		margin-left: 10px;
}
}
*[data-v-741978ef]::after {
	content: "";
	clear: both;
	display: table;
}
.site-title[data-v-741978ef],
.site-title[data-v-741978ef]:visited {
	color: white;
}
.tenant-button[data-v-741978ef] {
	max-width: 80px;
	overflow: hidden;
	text-overflow: ellipsis;
}
