
.v-text-field_search[data-v-251a2636] {
    padding-top: 0;
    margin-top: 0;
}
.extras-list[data-v-251a2636] {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.extras-list > li > span[data-v-251a2636]:last-of-type {
    float: right;
    margin-left:10px
}
.itemRowSideColor-grey[data-v-251a2636] {
    border: 5px solid #BDBDBD;;
    background-color: #BDBDBD;
    border-radius: 3px;
    display: inline;
}
.itemRowSideColor-blue[data-v-251a2636] {
    border: 5px solid #42A5F5;
    background-color: #42A5F5;
    border-radius: 3px;
    display: inline;
}
.itemRowSideColor-green[data-v-251a2636] {
    border: 5px solid #66BB6A;
    background-color: #66BB6A;
    border-radius: 3px;
    display: inline;
}
.itemRowSideColor-yellow[data-v-251a2636] {
    border: 5px solid #FFEE58;
    background-color: #FFEE58;
    border-radius: 3px;
    display: inline;
}
.itemRowSideColor-orange[data-v-251a2636] {
    border: 5px solid #FFA726;
    background-color: #FFA726;
    border-radius: 3px;
    display: inline;
}

