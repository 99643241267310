
.v-text-field_search {
    padding-top: 0;
    margin-top: 0;
}
.extras-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.extras-list > li > span:last-of-type {
    float: right;
    margin-left:10px
}
.itemRow-removed {
  background-color: #bfbfbf;
}
.itemRowSideColor-grey {
    border: 5px solid #BDBDBD;;
    background-color: #BDBDBD;
    border-radius: 3px;
    display: inline;
}
.itemRowSideColor-blue {
    border: 5px solid #42A5F5;
    background-color: #42A5F5;
    border-radius: 3px;
    display: inline;
}
.itemRowSideColor-green {
    border: 5px solid #66BB6A;
    background-color: #66BB6A;
    border-radius: 3px;
    display: inline;
}
.itemRowSideColor-yellow {
    border: 5px solid #FFEE58;
    background-color: #FFEE58;
    border-radius: 3px;
    display: inline;
}
.itemRowSideColor-orange {
    border: 5px solid #FFA726;
    background-color: #FFA726;
    border-radius: 3px;
    display: inline;
}
.line-limit-3 {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3;
   -webkit-box-orient: vertical;
}
.comments-tooltip {
  white-space: pre-line;
  max-width: 400px;
}

